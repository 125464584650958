body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.text-green {
  color: #51F8C2;
}

.header-background {
  background: #111827;
}

.footer-background {
  background: #FDC5B4;
}

.container-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.share-buttons {}

.share-buttons:hover {
  color: white;
}

/* purgecss start ignore */
.show-svg {
  visibility: visible;
}
/* purgecss end ignore */
