.check input:invalid {
  border-color: red;
}

/* FLOATING LABEL */
.label-floating input:not(:placeholder-shown),
.label-floating textarea:not(:placeholder-shown) {
  padding-top: 1.4rem;
}
.label-floating input:not(:placeholder-shown) ~ label,
.label-floating textarea:not(:placeholder-shown) ~ label {
  font-size: 0.8rem;
  transition: all 0.2s ease-in-out;
  color: #1f9d55;
  opacity: 1;
}

/*.simplebackground-dark-bg {*/
/*  background-image: url('/assets/images/darkbackground.png');*/
/*  height: 32rem;*/
/*}*/

.background-dark-bg {
  background: #111827;
}

.custom-green-bg {
  background: #058238;
}

.custom-green-bg:hover {
  background: #6ab038;
}

.custom-pink-bg {
  background: #e734ba;
}

.custom-pink-bg:hover {
  background: #ec47c3;
}

